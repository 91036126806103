import React from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import ImgSection from '../components/rows/imgsection'
import Outro from '../components/rows/outro'

import Banner from '../images/img/hero/partners-hero.jpg'

export default function OurPartners({data}) { // <== Make sure data is sent to the function

    return (
        <Layout>
        <Seo title="Our Partners" />
        <Hero 
            title="Our Partners"
            banner={Banner}
        />
        {data.allStrapiOurPartners.edges.map(({ node }) => ( // <== Map out the data from the node.
            <> 
                <Intro key={node.partner_intro.id}
                    title={node.partner_intro.title}
                    subtitle={node.partner_intro.subtitle}
                    description={node.partner_intro.description}
                />
                <ImgSection key={node.partner_img.id}
                    buttonOrange
                    subtitle={node.partner_img.subtitle}
                    title={node.partner_img.title}
                    description={node.partner_img.description}      
                    image={node.partner_img.image.localFile.publicURL}
                    link={node.partner_img.button_title}
                    href={node.partner_img.button_link}
                />
                <Outro key={node.partner_outro.id}
                    subtitle={node.partner_outro.subtitle}
                    title={node.partner_outro.title}
                    link={node.partner_outro.button_title}
                    href={node.partner_outro.button_link}
                />
            </>
        ))}            
        </Layout>    
    )
}


export const query = graphql`
query OurPartners {
  allStrapiOurPartners {
    edges {
      node {
        partner_intro {
          id
          subtitle
          title
          description
        }
        partner_img {
          id
          subtitle
          title
          description
          image {
            localFile {
              publicURL
            }
          }
          button_link
          button_title
        }
        partner_outro {
          id
          subtitle
          title
          button_title
          button_link
        }
      }
    }
  }
}

`